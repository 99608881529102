import React from 'react';

import {I18n, Button} from 'components/shared';

import styles from './styles.module.scss';

export const RegistrationsBanner = () => {
  const scrollToForm = () => {
    return document.getElementById('form')?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h1 className={styles.title}>
          <I18n tKey='app.registration_title' />
        </h1>
        <div className={styles.subtitle}>
          <I18n tKey='app.registration_subtitle' />
        </div>
        <Button onClick={scrollToForm} type='button' label='app.registration_button_title' />
      </div>
    </div>
  );
};

export default RegistrationsBanner;
