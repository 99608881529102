import React from 'react';

import {I18n} from 'components/shared';
import logo from 'assets/logo.png';
import topaz from 'assets/topaz.png';
import gla from 'assets/gla.png';

import styles from './styles.module.scss';

export const Partners = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.block}>
          <div className={styles.title}>
            <I18n tKey='app.partners.sponsors' />
          </div>
          <div className={styles.logoWrapper}>
            <img className={styles.logo} src={logo} alt='logo' />
          </div>
        </div>
        <div className={styles.block}>
          <div className={styles.title}>
            <I18n tKey='app.partners.organizers' />
          </div>
          <div className={styles.logoWrapper}>
            <img className={styles.logo} src={topaz} alt='topaz' />
          </div>
        </div>
        <div className={styles.block}>
          <div className={styles.title}>
            <I18n tKey='app.partners.partners' />
          </div>
          <div className={styles.logoWrapper}>
            <img className={styles.logo} src={gla} alt='gla' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
