import {VALIDATION} from 'globalConstants';

export const inputOptions = {
  firstName: {required: true},
  lastName: {required: true},
  middleName: {required: true},
  phone: {required: true},
  birthDate: {required: true, pattern: /[12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])/},
  document: {required: true},
  nickname: {required: true},
  teamName: {required: true},
  steamId: {required: true},
  password: {required: true},
  checkbox: {required: true},

  username: {
    required: true,
    pattern: {
      value: VALIDATION.email,
      message: 'error.invalid_email',
    },
  },
};

export const defaultValues = {
  username: '',
  country: 'UZ',
  firstName: '',
  password: Math.random().toString(36).slice(2, 10),
  gdpr: true,
  marketing: false,
  sourceUrl: 'https://dota2022.passport.gg/',
  lastName: '',
  middleName: '',
  phone: '',
  birthDate: '',
  document: '',
  nickname: '',
  teamName: '',
  checkbox: true,
};
