import React from 'react';

import styles from './styles.module.scss';
import {I18n} from '../../../../shared';

export const TitleBanner = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.title}>
          <I18n tKey='app.registration_title' />
        </div>
      </div>
    </div>
  );
};

export default TitleBanner;
