import React from 'react';
import classNames from 'classnames';

import {ReactComponent as PreloaderPic} from './assets/preloader.svg';

import styles from './style.module.scss';

type Props = {
  isActive?: boolean;
  isBgVisible?: boolean;
  className?: string;
};

const Preloader: React.FC<Props> = ({className, isBgVisible = true, isActive = false}) => {
  if (!isActive) {
    return null;
  }

  return (
    <div className={classNames(styles.preloaderWrapper, className, {[styles.withBg]: isBgVisible})}>
      <div className={styles.loader}>
        <PreloaderPic className={styles.preloader} />
      </div>
    </div>
  );
};

export default Preloader;
