import React from 'react';
import {NavLink} from 'react-router-dom';

import logo from 'assets/logo.png';

import {Menu} from './components';
import styles from './styles.module.scss';

export const Header: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        <NavLink className={styles.logo} to='/'>
          <img src={logo} alt='logo' />
        </NavLink>
        <Menu />
      </header>
    </div>
  );
};

export default Header;
