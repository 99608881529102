import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';

import './App.module.scss';
import {Header, Footer} from 'components/layout';
import {Home} from './components/pages';

export const App = () => {
  return (
    <Router>
      <div className='App'>
        <Header />

        <Home />

        <Footer />
      </div>
    </Router>
  );
};

export default App;
