import React from 'react';
import {NavLink} from 'react-router-dom';

import {I18n} from 'components/shared';

import {Menu} from './constants';
import {MenuTypes} from './types';
import styles from './styles.module.scss';

export const MenuApp = () => {
  return (
    <div className={styles.menu}>
      {Menu.map((item: MenuTypes) => (
        <div key={item.id}>
          <NavLink to={item.href} className={styles.item} target='_blank'>
            <I18n tKey={item.title} />
          </NavLink>
        </div>
      ))}
    </div>
  );
};

export default MenuApp;
