import React, {useState} from 'react';
import {useForm, FormProvider, SubmitHandler} from 'react-hook-form';
import {NavLink} from 'react-router-dom';

import axios from 'api/axios';
import {Button, I18n, Input, Preloader, Checkbox} from 'components/shared';

import styles from './styles.module.scss';
import {defaultValues, inputOptions} from './constants';
import {FormInputs} from './types';

export const RegistartionForm = () => {
  const [message, setMessage] = useState<string>('');
  const [emailMessage, setEmailMessage] = useState<string>('');
  const [gdprChecked, setGdprChecked] = useState<boolean>(true);
  console.log(gdprChecked);

  const [loading, setLoading] = useState<boolean>(false);
  const [isCreateAccount, setIsCreateAccount] = useState(false);

  const methods = useForm<FormInputs>({defaultValues});
  console.log(emailMessage);
  const onCheckboxChange = (e: {target: {name: string}}) => {
    if (e.target.name === 'gdpr') setGdprChecked(!gdprChecked);
  };
  const {
    handleSubmit,
    formState: {errors},
  } = methods;

  const onSubmit: SubmitHandler<FormInputs> = async data => {
    setLoading(true);
    axios
      .post('/auth/register', data)
      .then((res: any) => {
        console.log(res);
        setLoading(false);
        setIsCreateAccount(true);
      })
      .catch(err => {
        setMessage(err);
        setEmailMessage(err.response.data.errors.username);
        setLoading(false);
      });
  };

  return (
    <div className={styles.wrapper} id='form'>
      <div className={styles.container}>
        <div className={styles.title}>
          <I18n tKey='app.form.end' />
        </div>

        <Preloader isActive={loading} />

        {/* {!loading && !isCreateAccount && (
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
              <Input
                placeholder='app.from.name'
                errorDescription={errors.firstName && 'error.required_field'}
                type='input'
                name='firstName'
                options={inputOptions.firstName}
              />
              <Input
                placeholder='app.from.surname'
                errorDescription={errors.lastName && 'error.required_field'}
                type='input'
                name='lastName'
                options={inputOptions.lastName}
              />
              <Input
                placeholder='app.from.father_name'
                errorDescription={errors.middleName && 'error.required_field'}
                type='input'
                name='middleName'
                options={inputOptions.middleName}
              />
              <Input
                placeholder='app.from.email'
                type='input'
                isError={!!errors.username}
                errorDescription={errors.username && 'error.invalid_email'}
                name='username'
                options={inputOptions.username}
              />
              {emailMessage === 'User with such email already exists.' ? (
                <div className={styles.error}>
                  <p>Belə e-poçtu olan istifadəçi artıq mövcuddur</p>
                </div>
              ) : (
                false
              )}

              <Input
                placeholder='app.from.phone'
                errorDescription={errors.phone && 'error.required_field'}
                type='input'
                name='phone'
                options={inputOptions.phone}
              />
              <Input
                placeholder='app.from.birthday'
                errorDescription={errors.birthDate && 'error.required_field_birth'}
                type='input'
                name='birthDate'
                options={inputOptions.birthDate}
              />
              <Input
                placeholder='app.from.passport'
                errorDescription={errors.document && 'error.required_field'}
                type='input'
                name='document'
                options={inputOptions.document}
              />
              <Input
                placeholder='app.from.nike'
                errorDescription={errors.nickname && 'error.required_field'}
                type='input'
                name='nickname'
                options={inputOptions.nickname}
              />
              <Input
                placeholder='app.from.steamId'
                errorDescription={errors.steamId && 'error.required_field'}
                type='input'
                name='steamId'
                options={inputOptions.steamId}
              />
              <Input
                placeholder='app.from.name_team'
                errorDescription={errors.teamName && 'error.required_field'}
                type='input'
                name='teamName'
                options={inputOptions.teamName}
              />

              <Checkbox
                onChange={onCheckboxChange}
                required
                errorDescription={errors.checkbox && 'error.required_field'}
                className={styles.checkbox}
                name='gdpr'
                label='app.form.confirm'
              >
                <>
                  <I18n tKey='app.form.confirm' />
                  &nbsp;
                  <NavLink className={styles.link} to='//app.passport.gg/terms-of-use' target='_blank'>
                    <I18n tKey='app.terms' />
                  </NavLink>
                  , &nbsp;
                  <NavLink className={styles.link} to='//app.passport.gg/privacy-policy' target='_blank'>
                    <I18n tKey='app.privet' />
                  </NavLink>
                  &nbsp;
                  <NavLink className={styles.link} to='//app.passport.gg/cookie-policy' target='_blank'>
                    <I18n tKey='app.cookie' />
                  </NavLink>
                </>
              </Checkbox>
              {!gdprChecked && (
                <div className={styles.error}>
                  <I18n tKey='error.required_field' />
                </div>
              )}
              <Button className={styles.button} type='submit' label='app.registration_button_title' />
              {message && (
                <div className={styles.error}>
                  <I18n tKey='alert.error_create_account' />
                </div>
              )}
            </form>
          </FormProvider>
        )} */}

        {isCreateAccount && (
          <div className={styles.alert}>
            <I18n tKey='alert.create_account' />
          </div>
        )}
      </div>
    </div>
  );
};

export default RegistartionForm;
