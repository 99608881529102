import React from 'react';

import {Button, I18n} from 'components/shared';

import styles from './styles.module.scss';

export const Rules = () => {
  const scrollToForm = () => {
    return document.getElementById('form')?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.title}>
          <I18n tKey='app.rules.title' />
        </div>
        <p>
          <I18n tKey='app.rules.description.one' />
        </p>
        <p>
          <I18n tKey='app.rules.description.two' />
        </p>
        <p>
          <I18n tKey='app.rules.description.three' />
        </p>
        <Button onClick={scrollToForm} className={styles.button} type='button' label='app.registration_button_title' />
      </div>
    </div>
  );
};

export default Rules;
