import React from 'react';

import {Partners, RegistartionForm, RegistrationsBanner, Rules, TitleBanner} from './components';

export const Home = () => {
  return (
    <div>
      <RegistrationsBanner />
      <Rules />
      <TitleBanner />
      <RegistartionForm />
      <Partners />
    </div>
  );
};

export default Home;
