import React, {memo} from 'react';
import classNames from 'classnames';
import {WithTranslation, withTranslation} from 'react-i18next';

interface IProps extends WithTranslation {
  tKey: string;
  onClick?: () => void;
  classname?: string;
}

const I18n: React.FC<IProps> = ({tKey, onClick, classname, t}) => {
  return (
    <span className={classNames(classname)} onClick={onClick}>
      {t(tKey)}
    </span>
  );
};

export default withTranslation()(memo(I18n));
