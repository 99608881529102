import React, {memo, ReactChild, ReactElement} from 'react';
import classNames from 'classnames';

import I18n from '../I18n';
import {ButtonTheme} from './types';
import styles from './style.module.scss';

type Props = {
  label?: string;
  children?: ReactChild;
  theme?: ButtonTheme;
  onClick?: () => void;
  isWrapPrevented?: boolean;
  iconElement?: ReactElement;
  isDisabled?: boolean;
  type: 'submit' | 'reset' | 'button';
  isFullWidth?: boolean;
  className?: string;
};

const Button: React.FC<Props> = ({
  isWrapPrevented = false,
  theme = ButtonTheme.Primary,
  iconElement,
  onClick,
  label = '',
  children,
  isDisabled = false,
  type,
  isFullWidth = false,
  className,
}) => {
  const btnStyle = classNames(styles.btn, styles[theme], {
    [styles.noWrap]: isWrapPrevented,
  });

  return (
    <button
      type={type}
      disabled={isDisabled}
      onClick={onClick}
      className={classNames(btnStyle, {[styles.fullWidth]: isFullWidth}, className)}
    >
      {children || <I18n tKey={label} />}
      {iconElement && iconElement}
    </button>
  );
};

export default memo(Button);
