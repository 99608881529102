import React from 'react';
import {NavLink} from 'react-router-dom';
import {I18n} from 'components/shared';
import heart from '../../../../assets/heart.png';

import styles from './styles.module.scss';

export const Footer = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <I18n tKey='app.footer' /> <img src={heart} alt='' />{' '}
        <NavLink target='_blank' to='//passport.gg' className={styles.link}>
          <I18n tKey='app.site_url' />
        </NavLink>
      </div>
    </div>
  );
};

export default Footer;
